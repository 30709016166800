import styled from "styled-components";

export const S = {
  ProfileImage: styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 0;
`,
};