import styled from "styled-components";

export const S = {
  Modal: styled.div`
    width: 70%;
    height: 40px;
    background: #464646;
    text-align: center;
    z-index: 2;
    border-radius: 8px;
    position: absolute;
    bottom: 240px;
  `,
  ModalWrap: styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  `,
};
