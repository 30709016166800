import styled from "styled-components";

const Line = styled.div`
  width: 100%;
  margin: 16px 0;
  height: 1px;
  flex-shrink: 0;
  background: var(--line, #e1e2e5);
`;

export default Line;
