import styled from 'styled-components';

export const S = {
  Nickname: styled.div`
    margin-bottom: 10px;
    display: flex;
    color: var(--black, #1E1E1E);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
  `
}