import styled from "styled-components";

const EditText = styled.span`
    cursor: pointer;
    color: var(--gray, #a7a7a7);
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
`;

const EditNbsp = styled.span`
    color: var(--gray, #a7a7a7);
    font-size: 14px;
    font-weight: 500;
`;

export { EditNbsp, EditText };
